import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { stardardRequest } from '../../utils';
import { API_BASE } from '../../constants/api.constant'

import {
  LEADS_FETCH,
  LEADS_FETCH_CALL_PEP,
  LEADS_FETCH_MAIL_INB,
  LEADS_FETCH_NEXT,
  LEADS_FETCH_BY_ID,
  LEADS_FETCH_SOURCE_BY_ID,
  LEADS_SET_FILTERS,
  LEADS_CALL_PEP_SET_FILTERS,
  LEADS_MAIL_INB_SET_FILTERS,
  LEADS_CREATE,
  LEADS_EDIT,
  LEADS_ACTIVE_TOGGLE,
  LEADS_FETCH_AUDITS_BY_ID,
  LEADS_FETCH_NOTES_BY_ID,
  LEADS_ADD_NOTES_BY_ID,
  LEADS_FETCH_EMAIL_BY_ID,
  LEADS_FETCH_EMAIL_THREAD_BY_ID,
  LEADS_ADD_EMAILS_BY_ID,
  LEADS_FETCH_EMAIL_TEMPLATES,
  LEADS_FETCH_EMAIL_TEMPLATE_CONTENT,
  LEADS_EMAIL_ACKNOWLEDGE,
  LEADS_STATS_TODAY,
  LEADS_EMAIL_ACKNOWLEDGE_ALL,
  LEADS_SET_MANUAL_STAGE,
  LEADS_SET_FORN_DATA,
  LEADS_CW_STATUS,
  LEADS_CW_JOB_STATUS,
  LEADS_ACTION_PAGE_LOADED,
  LEADS_CALL_HISTORY,
  LEADS_FETCH_AGENTS,
  LEADS_FETCH_QA_BY_ID,
  LEADS_SH_PRE_FLIGHT_COMMIT,
  LEADS_STATS_SH_TODAY,
  LEADS_AGENT_CALL_SCATTER,
  LEADS_CALL_EMOTIONAL_SUMMARY
} from '../actions';

export function* watchLeadsFetchRequest() {
  const action = LEADS_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.leads.grid_options.leads.filters||{}
      const { mode, _id } = payload
      let url = null
      switch (mode) {
        case 'agent-qa-performance':
        case 'agent-qa-performance-agent':
          url = `${API_BASE}/v1/leads/${mode}/fetch`
          break
        default:
          url = `${API_BASE}/v1/leads/fetch`
          break
      }
      return {
        method: 'post',
        url,
        data: {...filters, _id}
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsFetchCallPepRequest() {
  const action = LEADS_FETCH_CALL_PEP
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.leads.grid_options.leads.filters||{}
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/pep/fetch`,
        data: {...filters}
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsFetchMailInbRequest() {
  const action = LEADS_FETCH_MAIL_INB
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.leads.grid_options.leads.filters||{}
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/mail/inbound/fetch`,
        data: {...filters}
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsFetchNextRequest() {
  const action = LEADS_FETCH_NEXT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.leads.grid_options.leads.filters
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/next`,
        data: filters
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchLeadsFiltersRequest() {
  yield takeEvery(LEADS_SET_FILTERS.REQUEST, function* ({ payload }) {
    yield put(LEADS_FETCH.requestAction({ ...payload }))
  })
}

export function* watchLeadsCallPepFiltersRequest() {
  yield takeEvery(LEADS_CALL_PEP_SET_FILTERS.REQUEST, function* ({ payload }) {
    yield put(LEADS_FETCH_CALL_PEP.requestAction({}))
  })
}

export function* watchLeadsMailInbFiltersRequest() {
  yield takeEvery(LEADS_MAIL_INB_SET_FILTERS.REQUEST, function* ({ payload }) {
    yield put(LEADS_FETCH_MAIL_INB.requestAction({}))
  })
}

export function* watchLeadsFetchIdRequest() {
  const action = LEADS_FETCH_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/leads/fetch/${payload.id}`
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchLeadsFetchAuditsIdRequest() {
  const action = LEADS_FETCH_AUDITS_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/public/audit/${payload.id}`
      }
    },
    dataPath: 'results',
    dataDefault: null,
  }));
}

export function* watchLeadsFetchSourceIdRequest() {
  const action = LEADS_FETCH_SOURCE_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/leads/fetch/${payload.source}/${payload.id}`
      }
    },
    dataPath: 'org',
    dataDefault: null,
  }));
}

export function* watchLeadsCreateRequest() {
  const action = LEADS_CREATE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/leads/create`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'orgs',
    dataDefault: [],
  }));
}

export function* watchLeadsEditRequest() {
  const action = LEADS_EDIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch',
      url: `${API_BASE}/v1/leads/edit`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'orgs',
    dataDefault: [],
  }));
}

export function* watchLeadsActiveToggleRequest() {
  const action = LEADS_ACTIVE_TOGGLE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/leads/deleted/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsNotesRequest() {
  const action = LEADS_FETCH_NOTES_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/leads/notes/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsAddNotesRequest() {
  const action = LEADS_ADD_NOTES_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/notes/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailsRequest() {
  const action = LEADS_FETCH_EMAIL_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/comms/mail/fetch/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailThreadRequest() {
  const action = LEADS_FETCH_EMAIL_THREAD_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/comms/mail/fetch/thread/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsAddEmailsRequest() {
  const action = LEADS_ADD_EMAILS_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      return {
        method: 'post',
        url: `${API_BASE}/v1/comms/mail/send`,
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailTemplatesRequest() {
  const action = LEADS_FETCH_EMAIL_TEMPLATES
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/comms/mail/templates`,
      }
    },
    dataPath: 'results',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailTemplateContentRequest() {
  const action = LEADS_FETCH_EMAIL_TEMPLATE_CONTENT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/comms/mail/template/content/${payload.template_id}/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchLeadsEmailAcknowledgeRequest() {
  const action = LEADS_EMAIL_ACKNOWLEDGE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/comms/mail/acknowledge/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsEmailAcknowledgeAllRequest() {
  const action = LEADS_EMAIL_ACKNOWLEDGE_ALL
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'patch'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/comms/mail/acknowledge/all/${payload.id}`,
        data: {
          ...payload,
          id: undefined
        }
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLeadsStatsTodayRequest() {
  const action = LEADS_STATS_TODAY
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/leads/stats/today${payload.is_pep ? '/pep' : ''}`,
        data: {
          ...payload,
          is_pep: undefined
        }
      }
    },
    dataPath: 'stats',
    dataDefault: {},
  }));
}

export function* watchLeadsSetManualStage() {
  const action = LEADS_SET_MANUAL_STAGE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/leads/manual/stage`,
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: {},
  }));
}

export function* watchLeadsSetFormData() {
  const action = LEADS_SET_FORN_DATA
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/leads/form/data`,
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: {},
  }));
}

export function* watchLeadsCWStatus() {
  const action = LEADS_CW_STATUS
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {},
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/leads/cw/status/${payload.id}`,
      }
    },
    dataPath: 'status',
    dataDefault: {},
  }));
}

export function* watchLeadsCWJobStatus() {
  const action = LEADS_CW_JOB_STATUS
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {},
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/leads/cw/job/status/${payload.id}`,
      }
    },
    dataPath: 'result',
    dataDefault: {},
  }));
}

export function* watchLeadsActionPageLoaded() {
  const action = LEADS_ACTION_PAGE_LOADED
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {},
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/page/loaded/v2`,
        data: payload
      }
    },
    dataPath: 'status',
    dataDefault: {},
  }));
}

export function* watchLeadsCallHistory() {
  const action = LEADS_CALL_HISTORY
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
    },
    requestOptionsFromPayload: (payload) => {
      const { id } = payload
      return {
        url: `${API_BASE}/v1/comms/call/history/${id}`
      }
    },
    dataPath: 'results',
    dataDefault: [],
  }));
}

export function* watchLeadsFetchAgents() {
  const action = LEADS_FETCH_AGENTS
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get',
      url: `${API_BASE}/v1/leads/agents`,
    },
    dataPath: 'results',
    dataDefault: [],
  }));
}

export function* watchLeadsFetchQAIdRequest() {
  const action = LEADS_FETCH_QA_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/comms/call/qa/${payload.id}`
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchLeadsSHreFlightCommitRequest() {
  const action = LEADS_SH_PRE_FLIGHT_COMMIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'post',
        url: `${API_BASE}/v1/leads/sh-pre-flight/commit/${payload.id}`
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchLeadsStatsSHTodayRequest() {
  const action = LEADS_STATS_SH_TODAY
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/leads/stats/today/service-history/pre-flight`,
        data: {
          ...payload,
          is_pep: undefined
        }
      }
    },
    dataPath: 'stats',
    dataDefault: {},
  }));
}

export function* watchLeadsAgentCallScatter() {
  const action = LEADS_AGENT_CALL_SCATTER
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/leads/report/agent/single/median`,
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: {},
  }));
}

export function* watchLeadsCallEmotionalSummary() {
  const action = LEADS_CALL_EMOTIONAL_SUMMARY
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'get'
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/leads/predictive/ei/summary/${payload.call_id}`,
        data: {
          ...payload
        }
      }
    },
    dataPath: 'result',
    dataDefault: {},
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchLeadsFetchRequest),
    fork(watchLeadsFetchCallPepRequest),
    fork(watchLeadsFetchMailInbRequest),
    fork(watchLeadsFetchNextRequest),
    fork(watchLeadsFetchIdRequest),
    fork(watchLeadsFetchAuditsIdRequest),
    fork(watchLeadsFetchSourceIdRequest),
    fork(watchLeadsCreateRequest),
    fork(watchLeadsEditRequest),
    fork(watchLeadsActiveToggleRequest),
    fork(watchLeadsFiltersRequest),
    fork(watchLeadsCallPepFiltersRequest),
    fork(watchLeadsMailInbFiltersRequest),
    fork(watchLeadsNotesRequest),
    fork(watchLeadsAddNotesRequest),
    fork(watchLeadsEmailsRequest),
    fork(watchLeadsAddEmailsRequest),
    fork(watchLeadsEmailThreadRequest),
    fork(watchLeadsEmailTemplatesRequest),
    fork(watchLeadsEmailTemplateContentRequest),
    fork(watchLeadsEmailAcknowledgeRequest),
    fork(watchLeadsEmailAcknowledgeAllRequest),
    fork(watchLeadsStatsTodayRequest),
    fork(watchLeadsSetManualStage),
    fork(watchLeadsSetFormData),
    fork(watchLeadsCWStatus),
    fork(watchLeadsCWJobStatus),
    fork(watchLeadsActionPageLoaded),
    fork(watchLeadsCallHistory),
    fork(watchLeadsFetchAgents),
    fork(watchLeadsFetchQAIdRequest),
    fork(watchLeadsSHreFlightCommitRequest),
    fork(watchLeadsStatsSHTodayRequest),
    fork(watchLeadsAgentCallScatter),
    fork(watchLeadsCallEmotionalSummary)
  ]);
}