export const createAction = (action) => {
  const conf = {
    ACTION: action,
    REQUEST: `${action}_REQUEST`,
    SUCCESS: `${action}_SUCCESS`,
    ERROR: `${action}_ERROR`
  };
  conf.requestAction = (payload) => ({
    type: conf.REQUEST,
    payload: payload ? payload : {}
  })
  conf.successAction = (payload) => ({
    type: conf.SUCCESS,
    payload: payload ? payload : {}
  })
  conf.errorAction = (error) => ({
    type: conf.ERROR,
    payload: { error }
  })
  return conf
};

/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* THEME */
export const THEME_GET = "THEME_GET";
export const THEME_SET = "THEME_SET";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_FACEBOOK = "LOGIN_FACEBOOK";
export const LOGIN_FIREBASE = "LOGIN_FIREBASE";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = createAction('LOGOUT_USER');
export const AUTH_RESET = createAction('AUTH_RESET')
export const ROLE_REQUEST = createAction('ROLE_REQUEST')

export const VERSION_CHECK = createAction('VERSION_CHECK')

export const FB_TOKEN_CHANGED = createAction('FB_TOKEN_CHANGED')

export const INITIAL_ROUTE = "INITIAL_ROUTE";

export const PUSH_REGISTER = "PUSH_REGISTER";
export const PUSH_REGISTER_STORE = "PUSH_REGISTER_STORE";
export const PUSH_REGISTER_SUCCESS = "PUSH_REGISTER_SUCCESS";
export const PUSH_REGISTER_ERROR = "PUSH_REGISTER_ERROR";

export const PM2_PROCESSES = createAction('PM2_PROCESSES')

export const GENERIC_API_ERROR = "GENERIC_API_ERROR";

/* TOWERS */
export const SITES_SET_SORT = createAction('SITES_SET_SORT');
export const SITES_SET_VIEW = createAction('SITES_SET_VIEW');
export const SITES_SET_SEARCH = createAction('SITES_SET_SEARCH');
export const SITES_SET_FILTERS = createAction('SITES_SET_FILTERS');
export const SITES_CRUD_MODE = createAction('SITES_CRUD_MODE');
export const SITES_CRUD_SUBMIT = createAction('SITES_CRUD_SUBMIT');
export const SITES_DELETE = createAction('SITES_DELETE');

export const CLIENTS_SET_SORT = createAction('CLIENTS_SET_SORT');
export const CLIENTS_SET_VIEW = createAction('CLIENTS_SET_VIEW');
export const CLIENTS_SET_SEARCH = createAction('CLIENTS_SET_SEARCH');
export const CLIENTS_SET_FILTERS = createAction('CLIENTS_SET_FILTERS');
export const CLIENTS_CRUD_MODE = createAction('CLIENTS_CRUD_MODE');
export const CLIENTS_CRUD_SUBMIT = createAction('CLIENTS_CRUD_SUBMIT');
export const CLIENTS_DELETE = createAction('CLIENTS_DELETE');

export const TOWERS_FETCH = createAction('TOWERS_FETCH');
export const TOWERS_SET_SORT = createAction('TOWERS_SET_SORT');
export const TOWERS_SET_VIEW = createAction('TOWERS_SET_VIEW');
export const TOWERS_SET_SEARCH = createAction('TOWERS_SET_SEARCH');
export const TOWERS_SET_FILTERS = createAction('TOWERS_SET_FILTERS');
export const TOWERS_CRUD_MODE = createAction('TOWERS_CRUD_MODE');
export const TOWERS_CRUD_SUBMIT = createAction('TOWERS_CRUD_SUBMIT');
export const TOWERS_DELETE = createAction('TOWERS_DELETE');
export const TOWERS_RELAY_MODE = createAction('TOWERS_RELAY_MODE');
export const TOWERS_RELAY_CRUD_SUBMIT = createAction('TOWERS_RELAY_CRUD_SUBMIT');
export const TOWERS_CAMERA_MODE = createAction('TOWERS_CAMERA_MODE');
export const CAMERAS_FETCH = createAction('CAMERAS_FETCH');
export const CAMERAS_CRUD_SUBMIT = createAction('CAMERAS_CRUD_SUBMIT');
export const TOWERS_CONFIG_CHECK = createAction('TOWERS_CONFIG_CHECK');
export const CAMERAS_CONFIG_CHECK = createAction('CAMERAS_CONFIG_CHECK');
export const TOWERS_W3W_CHECK = createAction('TOWERS_W3W_CHECK');

/* USERS */
export const USERS_FETCH = createAction('USERS_FETCH');
export const USERS_FETCH_BY_ID = createAction('USERS_FETCH_BY_ID');
export const USERS_FETCH_CLEAR = createAction('USERS_FETCH_CLEAR');
export const USERS_SET_SORT = createAction('USERS_SET_SORT');
export const USERS_SET_VIEW = createAction('USERS_SET_VIEW');
export const USERS_SET_SEARCH = createAction('USERS_SET_SEARCH');
export const USERS_SET_FILTERS = createAction('USERS_SET_FILTERS');
export const USERS_CRUD_MODE = createAction('USERS_CRUD_MODE');
export const USERS_CRUD_SUBMIT = createAction('USERS_CRUD_SUBMIT');
export const USERS_CREATE = createAction('USERS_CREATE');
export const USERS_EDIT = createAction('USERS_EDIT');
export const USERS_ACTIVE_TOGGLE = createAction('USERS_ACTIVE_TOGGLE');
export const USERS_ROLES_SET = createAction('USERS_ROLES_SET');
export const USERS_RESET_PASS_LINK = createAction('USERS_RESET_PASS_LINK');
export const USERS_RESET_PASS_SEND = createAction('USERS_RESET_PASS_SEND');
export const USERS_ACTIVITY = createAction('USERS_ACTIVITY');

/* ORGS */
export const LEADS_FETCH = createAction('LEADS_FETCH');
export const LEADS_FETCH_CALL_PEP = createAction('LEADS_FETCH_CALL_PEP');
export const LEADS_FETCH_MAIL_INB = createAction('LEADS_FETCH_MAIL_INB');
export const LEADS_FETCH_CLEAR = createAction('LEADS_FETCH_CLEAR');
export const LEADS_FETCH_NEXT = createAction('LEADS_FETCH_NEXT');
export const LEADS_FETCH_BY_ID = createAction('LEADS_FETCH_BY_ID');
export const LEADS_FETCH_AUDITS_BY_ID = createAction('LEADS_FETCH_AUDITS_BY_ID');
export const LEADS_FETCH_SOURCE_BY_ID = createAction('LEADS_FETCH_SOURCE_BY_ID');
export const LEADS_SET_SORT = createAction('LEADS_SET_SORT');
export const LEADS_SET_VIEW = createAction('LEADS_SET_VIEW');
export const LEADS_SET_SEARCH = createAction('LEADS_SET_SEARCH');
export const LEADS_SET_FILTERS = createAction('LEADS_SET_FILTERS');
export const LEADS_CALL_PEP_SET_FILTERS = createAction('LEADS_CALL_PEP_SET_FILTERS');
export const LEADS_MAIL_INB_SET_FILTERS = createAction('LEADS_MAIL_INB_SET_FILTERS');
export const LEADS_CRUD_MODE = createAction('LEADS_CRUD_MODE');
export const LEADS_CRUD_SUBMIT = createAction('LEADS_CRUD_SUBMIT');
export const LEADS_CREATE = createAction('LEADS_CREATE');
export const LEADS_EDIT = createAction('LEADS_EDIT');
export const LEADS_ACTIVE_TOGGLE = createAction('LEADS_ACTIVE_TOGGLE');
export const LEADS_ACTION_CURRENT_STEP = createAction('LEADS_ACTION_CURRENT_STEP');
export const LEADS_ACTION_PAGE_LOADED = createAction('LEADS_ACTION_PAGE_LOADED');
export const LEADS_ACTION_STEP_STATUS = createAction('LEADS_ACTION_STEP_STATUS');
export const LEADS_FETCH_NOTES_BY_ID = createAction('LEADS_FETCH_NOTES_BY_ID');
export const LEADS_ADD_NOTES_BY_ID = createAction('LEADS_ADD_NOTES_BY_ID');
export const LEADS_FETCH_EMAIL_BY_ID = createAction('LEADS_FETCH_EMAIL_BY_ID');
export const LEADS_FETCH_EMAIL_THREAD_BY_ID = createAction('LEADS_FETCH_EMAIL_THREAD_BY_ID');
export const LEADS_FETCH_EMAIL_THREAD_CLEAR = createAction('LEADS_FETCH_EMAIL_THREAD_CLEAR');
export const LEADS_ADD_EMAILS_BY_ID = createAction('LEADS_ADD_EMAILS_BY_ID');
export const LEADS_FETCH_QA_BY_ID = createAction('LEADS_FETCH_QA_BY_ID');
export const LEADS_LOCKED = createAction('LEADS_LOCKED');
export const LEADS_FETCH_EMAIL_TEMPLATES = createAction('LEADS_FETCH_EMAIL_TEMPLATES');
export const LEADS_FETCH_EMAIL_TEMPLATE_CONTENT = createAction('LEADS_FETCH_EMAIL_TEMPLATE_CONTENT');
export const LEADS_EMAIL_ACKNOWLEDGE = createAction('LEADS_EMAIL_ACKNOWLEDGE');
export const LEADS_EMAIL_ACKNOWLEDGE_ALL = createAction('LEADS_EMAIL_ACKNOWLEDGE_ALL');
export const LEADS_STATS_TODAY = createAction('LEADS_STATS_TODAY');
export const LEADS_STATS_SH_TODAY = createAction('LEADS_STATS_SH_TODAY');
export const LEADS_SET_MANUAL_STAGE = createAction('LEADS_SET_MANUAL_STAGE');
export const LEADS_SET_FORN_DATA = createAction('LEADS_SET_FORN_DATA');
export const LEADS_CW_STATUS = createAction('LEADS_CW_STATUS');
export const LEADS_CW_JOB_STATUS = createAction('LEADS_CW_JOB_STATUS');
export const LEADS_CALL_HISTORY = createAction('LEADS_CALL_HISTORY');
export const LEADS_FETCH_AGENTS = createAction('LEADS_FETCH_AGENTS');
export const LEADS_SH_PRE_FLIGHT_COMMIT = createAction('LEADS_SH_PRE_FLIGHT_COMMIT');
export const LEADS_AGENT_CALL_SCATTER = createAction('LEADS_AGENT_CALL_SCATTER');
export const LEADS_CALL_EMOTIONAL_SUMMARY = createAction('LEADS_CALL_EMOTIONAL_SUMMARY');

/* REPORT */
export const REPORT_PRE_QUAL = createAction('REPORT_PRE_QUAL');
/* REPORT */

/* GROUPS */
export const GROUPS_FETCH = createAction('GROUPS_FETCH');
export const GROUPS_SET_SORT = createAction('GROUPS_SET_SORT');
export const GROUPS_SET_VIEW = createAction('GROUPS_SET_VIEW');
export const GROUPS_SET_SEARCH = createAction('GROUPS_SET_SEARCH');
export const GROUPS_SET_FILTERS = createAction('GROUPS_SET_FILTERS');
export const GROUPS_CRUD_MODE = createAction('GROUPS_CRUD_MODE');
export const GROUPS_CRUD_SUBMIT = createAction('GROUPS_CRUD_SUBMIT');
export const GROUPS_CREATE = createAction('GROUPS_CREATE');
export const GROUPS_EDIT = createAction('GROUPS_EDIT');
export const GROUPS_ACTIVE_TOGGLE = createAction('GROUPS_ACTIVE_TOGGLE');
export const GROUPS_ROLES_SET = createAction('GROUPS_ROLES_SET');

/* MAX_LISTS */
export const MAX_LISTS_FETCH = createAction('MAX_LISTS_FETCH');
export const MAX_LISTS_SET_SORT = createAction('MAX_LISTS_SET_SORT');
export const MAX_LISTS_SET_VIEW = createAction('MAX_LISTS_SET_VIEW');
export const MAX_LISTS_SET_SEARCH = createAction('MAX_LISTS_SET_SEARCH');
export const MAX_LISTS_SET_FILTERS = createAction('MAX_LISTS_SET_FILTERS');
export const MAX_LISTS_CRUD_MODE = createAction('MAX_LISTS_CRUD_MODE');
export const MAX_LISTS_CRUD_SUBMIT = createAction('MAX_LISTS_CRUD_SUBMIT');
export const MAX_LISTS_CREATE = createAction('MAX_LISTS_CREATE');
export const MAX_LISTS_EDIT = createAction('MAX_LISTS_EDIT');
export const MAX_LISTS_ACTIVE_TOGGLE = createAction('MAX_LISTS_ACTIVE_TOGGLE');
export const MAX_LISTS_ROLES_SET = createAction('MAX_LISTS_ROLES_SET');

/* METRICS */
export const METRICS_FETCH = createAction('METRICS_CONFIGS');
export const METRICS_ACTIONS_FETCH = createAction('METRICS_ACTIONS_FETCH');
export const METRICS_STATS = createAction('METRICS_STATS');
export const METRICS_FETCH_LATEST = createAction('METRICS_FETCH_LATEST');
export const METRICS_CHART_TIMELINE = createAction('METRICS_CHART_TIMELINE');
export const METRIC_HISTORY_FETCH = createAction('METRIC_HISTORY_FETCH');
export const METRICS_HISTORY_SET_FILTERS = createAction('METRICS_HISTORY_SET_FILTERS');

/* TEMPLATES */
export const COMMS_TEMPLATES_FETCH = createAction('COMMS_TEMPLATES_FETCH');
export const COMMS_TEMPLATES_CREATE = createAction('COMMS_TEMPLATES_CREATE');
export const COMMS_TEMPLATES_EDIT = createAction('COMMS_TEMPLATES_EDIT');
export const COMMS_TEMPLATES_DELETE = createAction('COMMS_TEMPLATES_DELETE');
export const COMMS_TEMPLATES_FETCH_BY_ID = createAction('COMMS_TEMPLATES_FETCH_BY_ID');
export const COMMS_TEMPLATES_SELECTED_CLEAR = createAction('COMMS_TEMPLATES_SELECTED_CLEAR');
export const COMMS_TEMPLATES_SET_FILTERS = createAction('COMMS_TEMPLATES_SET_FILTERS');

export const RELAY_SET = createAction('RELAY_SET');

export const BUILDS_FETCH = createAction('BUILDS_FETCH');

export const RESTIFY_ENDPOINT_GET = createAction('RESTIFY_ENDPOINT_GET');

export const EVENTS_CONNECT = createAction('EVENTS_CONNECT');
export const EVENTS_CONNECTED = createAction('EVENTS_CONNECTED');
export const EVENTS_DISCONNECT = createAction('EVENTS_DISCONNECT');
export const EVENTS_SEND_EVENT = createAction('EVENTS_SEND_EVENT');

/* DEV */
export const REDUX_LOGGER = createAction('REDUX_LOGGER')

/* BASE */
export const SET_CURRENT_ROUTE_KEY = createAction('SET_CURRENT_ROUTE_KEY');

export * from "./api/actions";
export * from "./auth/actions";